import { useAppContext } from '@components/app-context'
import { JsonRpcProvider, JsonRpcSigner, WebSocketProvider } from '@ethersproject/providers'
import { rpcList, WebSocketsRPCList } from 'helpers/utils'
import { Nullable } from 'interfaces/interfaces'
import { useMemo } from 'react'

export enum ProviderType {
	ReadOnlyWebsocket = 'wsProvider',
	ReadOnly = 'jsonRpcProvider',
	ReadAndWrite = 'signer',
}

function useProvider(type: ProviderType.ReadOnly, chainId?: number): Nullable<JsonRpcProvider>
function useProvider(type: ProviderType.ReadOnlyWebsocket, chainId?: number): Nullable<WebSocketProvider>
function useProvider(type: ProviderType.ReadAndWrite): Nullable<JsonRpcSigner>
function useProvider(type: ProviderType, chainId?: number): unknown {
	const { chainId: connectedChainId, walletProvider } = useAppContext()

	return useMemo(() => {
		if (!(chainId || connectedChainId)) return null

		switch (type) {
			case ProviderType.ReadOnly:
				return new JsonRpcProvider(rpcList[chainId ?? connectedChainId])

			case ProviderType.ReadOnlyWebsocket:
				return new WebSocketProvider(WebSocketsRPCList[chainId ?? connectedChainId])

			case ProviderType.ReadAndWrite:
				if (!walletProvider) return null
				return walletProvider.getSigner()

			default:
				return null
		}
	}, [chainId, connectedChainId, type, walletProvider])
}

export default useProvider
