import { ContractInterface, ethers, Signer } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useAppContext } from '../components/app-context'
import ERC20ABI from '../contracts/abis/ERC20TokenABI.json'
import {
	CoinviseABI as CoinviseContract,
	CoinviseABI__factory,
	ERC20TokenABI,
	FxERC20RootTunnel,
	FxERC20RootTunnel__factory,
	Multisend,
	Multisend__factory,
	Vesting,
	VestingFactory,
	VestingFactory__factory,
	Vesting__factory,
} from '../contracts/abis/types'
import { ethPsuedoAddress } from '../helpers/constants'
import { contractAddresses } from '../helpers/utils'
import getContract, { GetContractTypeEnum } from '../utils/getContract'

export interface UseContractConfig {
	/** Contract address or ENS name */
	addressOrName?: string
	/** Contract interface or ABI */
	contractInterface?: ContractInterface
	/**
	 * One of the GetContractTypeEnum
	 * Optional if signer is provided
	 */
	type?: GetContractTypeEnum
	/** defines the chain the contract lives on */
	chain?: number
	/** Optional parameter to decide whether to initialize the contract or not */
	shouldInitialize?: boolean
	/**
	 * To use a specific signer for the contract
	 * Takes precedence over `type`
	 *
	 */
	signer?: Signer
}

/**
 * @description
 *
 * ~ What it does? ~
 *
 * Returns instance of a contract on the blockchain and gives options to read values from contracts
 * or write transactions into them
 *
 *
 * ~ What `type` to use? ~
 *
 * GetContractTypeEnum.ReadOnly for read-only purposes (does not depend on user and user's current chain)
 *
 * GetContractTypeEnum.ReadAndWrite for read and write purposes (depends on user and user's current chain)
 *
 * GetContractTypeEnum.ReadOnlyWebsocket for read-only purposes when constant connect is needed
 * Eg: For listening to events
 *
 * @param {UseContractConfig} config
 * @returns instance of the contract or null if condition is not met
 */
export const useContract = <T extends ethers.Contract = ethers.Contract>({
	addressOrName,
	type = GetContractTypeEnum.ReadAndWrite,
	chain,
	shouldInitialize = true,
	contractInterface,
	signer,
}: UseContractConfig): T | null => {
	const { walletProvider, chainId } = useAppContext()

	return useMemo(() => {
		if (!addressOrName || !contractInterface || addressOrName === ethPsuedoAddress || !shouldInitialize) return null
		if (!signer && !walletProvider) return null
		if (!chain && !chainId) return null

		try {
			return getContract(addressOrName, contractInterface, {
				type,
				chainId: chain ?? chainId,
				signer: signer ?? walletProvider.getSigner(),
			})
		} catch (error) {
			console.error('Failed to get contract', error)
			return null
		}
	}, [addressOrName, contractInterface, shouldInitialize, type, chain, chainId, signer, walletProvider]) as T
}

/**
 * @returns null if token address is eth psuedo address
 */
export const useTokenContract = (
	tokenAddress?: string,
	type?: GetContractTypeEnum,
	chainId?: number,
	disable?: boolean
): ERC20TokenABI | null => {
	return useContract<ERC20TokenABI>({
		addressOrName: tokenAddress,
		contractInterface: ERC20ABI,
		type,
		chain: chainId,
		shouldInitialize: !disable,
	})
}

export const useCoinviseContract = (type?: GetContractTypeEnum): CoinviseContract | null => {
	const { chainId } = useAppContext()
	const address = contractAddresses.coinvise[chainId]
	return useContract<CoinviseContract>({ addressOrName: address, contractInterface: CoinviseABI__factory.abi, type })
}

export const useRootTunnelContract = (type?: GetContractTypeEnum): FxERC20RootTunnel | null => {
	const { chainId } = useAppContext()
	const [shouldInit, setShouldInit] = useState(false)
	const [address, setAddress] = useState('')

	useEffect(() => {
		if (chainId && [1, 5].includes(chainId)) {
			setAddress(contractAddresses.fxERC20RootTunnel[chainId])
			setShouldInit(true)
		}
	}, [chainId])

	return useContract<FxERC20RootTunnel>({
		addressOrName: address,
		contractInterface: FxERC20RootTunnel__factory.abi,
		type,
		chain: chainId,
		shouldInitialize: shouldInit,
	})
}

export const useMultisend = (type?: GetContractTypeEnum): Multisend => {
	const { chainId } = useAppContext()
	const address = contractAddresses.multisend[chainId]
	return useContract<Multisend>({ addressOrName: address, contractInterface: Multisend__factory.abi, type })
}
/**
 * Hook to interact with Vesting Proxy Factory Contract
 */
export const useVestingFactoryContract = (type?: GetContractTypeEnum): VestingFactory | null => {
	const { chainId } = useAppContext()
	const address = contractAddresses.vestingFactory[chainId]
	return useContract<VestingFactory>({ addressOrName: address, contractInterface: VestingFactory__factory.abi, type })
}

export const useVestingContract = (
	vestingAddress?: string,
	type?: GetContractTypeEnum,
	chainId?: string
): Vesting | null => {
	return useContract<Vesting>({
		addressOrName: vestingAddress,
		contractInterface: Vesting__factory.abi,
		type,
		chain: chainId && Number(chainId),
	})
}
